* {
  box-sizing: border-box;
}

.App {
  height: 100dvh;
  width: 100dvw;
  position: relative;
}

.fill {
  width: 100%;
  height: 100%;
}

.fullscreen-iframe {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
}

.fab {
  position: fixed;
  bottom: 2em;
  right: 3em;
  z-index: 1000;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.splash-image {
  width: 180px;
}